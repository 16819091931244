import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import moment from 'moment';
import { SERVER_URL } from 'src/app/constant/constant';
import { CmnServiceService } from 'src/app/SERVICE/cmnService/cmn-service.service';
import { Role } from 'src/app/shared/configs/role.enum';
import { AlertController } from '@ionic/angular';
import { NameValidator } from 'src/app/shared/validator/name.validation';
import { AuthServiceService } from 'src/app/SERVICE/authService/auth-service.service';


@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss'],
})
export class AddProviderComponent implements OnInit {
  inviteDoctorForm: FormGroup;
  showLoader = false;
  // schedule
  dates = [];
  createSlot: FormGroup;
  userData: any;
  showAppointmentLoader: boolean;
  slots: any = [];
  selectedDate: any = moment().format('YYYY-MM-DD');
  currentUserRole: Role;
  doctorId: any;
  doctors: any[] = [];
  selectedDoctor: any;
  isPastDate = false;
  startTime: any;
  endTime: any;
  duration = 15;
  isSubmitedSlot = true;
  schedule_ids = [];
  ProviderDetails:any|Array<any>;
  // 
  @Input() orgId: string;
  step:number = 1;
  constructor(
    public modal: ModalController,
    private fb: FormBuilder,
    private http: HttpClient,
    private cmnService: CmnServiceService,
    private router:Router,
    private navCtrl: NavController,
    private alertController: AlertController,
    private authService: AuthServiceService
  ) {}

  ngOnInit() {
    this.inviteDoctorForm = this.fb.group({
      first_name: new FormControl('', [
        Validators.required,
        NameValidator.whiteSpace,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      last_name: new FormControl('', [
        Validators.required,
        NameValidator.whiteSpace,
        Validators.pattern("^[a-zA-Z -']+"),
      ]),
      email: new FormControl('', [
        Validators.required,
        NameValidator.whiteSpace,
        Validators.email,
      ]),
      phone: new FormControl('',[
        Validators.required,
        NameValidator.whiteSpace,
        Validators.pattern('[0-9 ]{10}'),
      ]),
      password: new FormControl('',[
        Validators.required,
        NameValidator.whiteSpace,
      ])
    });
  }

  get f() {
    return this.inviteDoctorForm?.controls;
  }

  onInviteClick() {
    let data = {
      organization_id: Number(this.orgId),
      first_name: this.inviteDoctorForm.value.first_name,
      last_name: this.inviteDoctorForm.value.last_name,
      email: this.inviteDoctorForm.value.email.toLowerCase(),
      phone: this.inviteDoctorForm.value.phone,
      password : this.inviteDoctorForm.value.password,
      prefix_dr : true,
    };
    this.ProviderDetails = data;
    this.showLoader = true;
    this.cmnService.showLoader();
    this.http.post(SERVER_URL + 'v2/signup_doctor', data).subscribe(
      (res:any) => {
        this.doctorId = res?.doctor_id;
        this.showLoader = false;
        this.cmnService.hideLoader();
        this.cmnService.showSuccess('Add Provider Successfully');
        this.step = 2;

        // this.router.navigate(['main/schedule']);
        // localStorage.setItem("provider",JSON.stringify(res?.doctor_id))
        // localStorage.setItem("provider_name", JSON.stringify(res?.data_info?.name))
        // setTimeout(() => {
        //   this.navCtrl.navigateForward('main/schedule');
        // }, 2000);
        // this.modal.dismiss('invited');
        this.userData = this.authService.currentUserValue;
        this.currentUserRole = this.userData?.type;
        // if (this.currentUserRole === Role.Doctor) {
          // this.doctorId = this.userData?.data?.id;
          this.selectedDoctor = data;
          this.getSchedules(this.selectedDate);
          this.createSlot = new FormGroup({
            startTime: new FormControl('', [Validators.required]),
            endTime: new FormControl('', [Validators.required]),
            duration: new FormControl('15', [Validators.required]),
          });
        // }
        // if (this.currentUserRole === Role.Organization) {
        //   const doctorId = JSON.parse(localStorage.getItem("provider"));
        //   if (doctorId){
        //   this.getDoctors(doctorId)
        //   }

        // }
      },
      (err) => {
        this.showLoader = false;
        this.cmnService.hideLoader();
        this.cmnService.showError(err?.message);
      }
    );
  }



  // schedule section

  onDateClick(event) {
    this.selectedDate = moment(event.date).format('YYYY-MM-DD');
    this.getSchedules(this.selectedDate);
    this.isDateToday(this.selectedDate);
  }

  isDateToday(date) {
    if (date != null && date != '') {
      const dateArr = date.split('-');
      const inputDate = new Date(
        '"' + dateArr[0] + '-' + dateArr[1] + '-' + dateArr[2] + '"'
      ).setHours(0, 0, 0, 0);
      const toDay = new Date().setHours(0, 0, 0, 0);
      if (inputDate > toDay) {
        this.isPastDate = false;
      } else if (inputDate == toDay) {
        this.isPastDate = false;
      } else {
        this.isPastDate = true;
      }
    }
  }

  saveSlot() {
    if (!this.createSlot.value.startTime || !this.createSlot.value.endTime) {
      this.cmnService.showWarning('Please select Start time and End time.');
      return;
    }
    if (!this.createSlot.value.duration) {
      this.cmnService.showWarning('Please select Duration of the slots.');
      return;
    }
    // this.showLoader = true;
    // this.cmnService.showLoader();
    // this.http.post(SERVER_URL + 'v2/signup_doctor', this.ProviderDetails).subscribe(
    //   (res:any) => {
    //     this.doctorId = res?.doctor_id;
        const data = {
          doctor_id: this.doctorId,
          date: this.selectedDate,
          start_time: this.createSlot.value.startTime,
          end_time: this.createSlot.value.endTime,
          minute: this.createSlot.value.duration,
        };
        // this.cmnService.showLoader();
        this.http.post(SERVER_URL + 'v4/create_doctor_schedule', data).subscribe(
          (res: any) => {
            this.cmnService.hideLoader();
            if (res?.success == 'true') {
              this.cmnService.showSuccess('Slot created Successfully');
              this.modal.dismiss('invited');

            } else {
              this.cmnService.showError(res?.message);
            }

            this.createSlot.reset();
            this.getSchedules(this.selectedDate);
          },
          (err) => {
            this.cmnService.showError(err?.message);
            this.cmnService.hideLoader();
          }
        );
      //   },(err)=>{
      //     this.cmnService.showError(err?.message);
      //     this.cmnService.hideLoader();
      //   }
      // )
  }

  onChangeStartTime(event) {
    this.startTime = new Date(
      this.selectedDate + ' ' + event?.target?.value
    ).getTime();
  }

  onChangeEndTime(event) {
    if (event) {
      this.endTime = new Date(
        this.selectedDate + ' ' + event?.target?.value
      ).getTime();
      const difference = this.endTime - this.startTime; // This will give difference in milliseconds
      const resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes < this.duration) {
        this.presentAlert();
        this.isSubmitedSlot = true;
        this.createSlot.patchValue({
          endTime: '',
        });
      } else {
        this.isSubmitedSlot = false;
      }
    }
  }

  onChangeDuration(event) {
    if (event) {
      this.duration = event.detail.value;
      const difference = this.endTime - this.startTime; // This will give difference in milliseconds
      const resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes < this.duration) {
        this.presentAlert();
        this.isSubmitedSlot = true;
        this.createSlot.patchValue({
          endTime: '',
        });
      } else {
        this.isSubmitedSlot = false;
      }
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'OOPS',
      subHeader: 'End time duration must be',
      message: 'Greater than or equal to ' + this.duration + ' ' + 'minute',
      buttons: ['OK'],
    });
    await alert.present();
  }

  getSchedules(date) {
    const data = {
      doctor_id: this.doctorId,
      date,
    };

    this.showAppointmentLoader = true;
    this.cmnService.showLoader();
    this.http.post(SERVER_URL + 'v4/doctor_ygoin_all_slot', data).subscribe(
      (res: any) => {
        this.showAppointmentLoader = false;
        this.cmnService.hideLoader();
        if (res?.doctorSlot) {
          this.slots = res?.doctorSlot;
          this.schedule_ids = res?.schedule_ids;
        } else {
          this.slots = [];
        }
      },
      (err) => {
        this.showAppointmentLoader = false;
        this.cmnService.hideLoader();
      }
    );
  }

  getDoctors(doctorID?:number) {
    const data = {
      organization_id: this.userData?.operator?.related_org_id,
    };
    this.http.post(SERVER_URL + 'v2/get_all_org_doctors', data).subscribe(
      (res: any) => {
        if (res?.data) {
          this.doctors = res?.data;
          if (doctorID){
            this.selectedDoctor = this.doctors.find((doctor)=> {if (doctorID == doctor?.id){return doctor}})
          }
          if (this.selectedDoctor?.id == doctorID && doctorID){
            this.doctorId = this.selectedDoctor?.id;
            this.getSchedules(this.selectedDate);
            this.cmnService.showInfo(`Please Add Schedule For ${this.selectedDoctor?.first_name} ${this.selectedDoctor?.last_name}`)

          } else {
            this.selectedDoctor = this.doctors[0];
            this.doctorId = this.selectedDoctor?.id;
            this.getSchedules(this.selectedDate);
          }
        }
      },
      (err) => {}
    );
  }

  onDoctorChange() {
    this.doctorId = this.selectedDoctor?.id;
    this.getSchedules(this.selectedDate);
  }

  async deleteSlot(slot) {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.slotDelete(slot);
          },
        },
      ],
    });

    await alert.present();
  }

  async resetSlot() {
    const alert = await this.alertController.create({
      header: 'Confirm',
      message:
        'Are you sure you want to reset slots? Please make sure that All slots for the day will be removed. ',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            this.slotReset();
          },
        },
      ],
    });

    await alert.present();
  }

  slotReset() {
    const data = {
      date: this.selectedDate,
      schedule_ids: this.schedule_ids,
      doctor_id: this.doctorId,
    };

    this.cmnService.showLoader();
    this.http
      .post(SERVER_URL + 'v4/remove_bulk_doctor_schedule', data)
      .subscribe(
        (res: any) => {
          this.cmnService.hideLoader();
          if (res?.success == 'true') {
            this.cmnService.showSuccess(res?.message);
            this.getSchedules(this.selectedDate);
          } else {
            this.cmnService.showWarning(res?.message);
          }
        },
        (err) => {
          this.cmnService.hideLoader();
        }
      );
  }

  slotDelete(slot) {
    const data = {
      schedule_id: slot.schedule_id,
      schedule_date: this.selectedDate,
      start_time: slot.start_time,
      end_time: slot.end_time,
    };
    this.cmnService.showLoader();
    this.http.post(SERVER_URL + 'v4/remove_schedule', data).subscribe(
      (res: any) => {
        this.cmnService.hideLoader();
        this.getSchedules(this.selectedDate);
      },
      (err) => {
        this.cmnService.hideLoader();
      }
    );
  }

}
