/* eslint-disable @typescript-eslint/naming-convention */
// this file is for production server only
export const environment = {
  apiURL: 'https://prod.ygoin.com/',
  OT_API_KEY: '47446961',
  PUSHER_KEY: '7e701fe2b176444f3af4',
  version: 'P2.1.2',
  serviceFee: 2.99,
  eKey: 'yg0in@987_',
  firebase: {
    apiKey: 'AIzaSyAzsCaUOBitgG1OQh2cHes56Yjo8ay8QyE',
    authDomain: 'ygoin-prod.firebaseapp.com',
    databaseURL: 'https://ygoin-prod-default-rtdb.firebaseio.com',
    projectId: 'ygoin-prod',
    storageBucket: 'ygoin-prod.appspot.com',
    messagingSenderId: '48467946904',
    appId: '1:48467946904:web:8fed8bb15c9b9723344fed',
    measurementId: 'G-4N0MKZY16W',
  },
  production: true,
  external_appointment_URL: "https://sickure-external.web.app/",
};
